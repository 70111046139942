import styled from 'styled-components';

export const StyledComingSoon = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url("./images/beach.jpeg");
  background-size: cover;
  background-position: center;
  h1 {
    color: #d65076;
    text-align: center;
    padding-top: 4rem;
    text-shadow: 5px 5px black;
  }
`;