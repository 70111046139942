import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    body{
        font-family: 'Poppins', sans-serif;
        background: ${({ theme }) => theme.colors.body};
        margin: 0;
    }
    img{
        max-width: 100%;
    }
      ul, li {
    list-style-type: none;
  }
`;

export default GlobalStyles;
