import React from "react";
import { Routes, Route } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Tutoring from "./pages/Tutoring";
import Contact from "./pages/Contact";
import Cheatsheets from "./pages/Cheatsheets";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Styles
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./components/styles/Global";

// Content
// import content from "./content";

// Theme
export const theme = {
  colors: {
    header: "#fff",
    body: "#fff",
    footer: "#326890",
  },
  mobile: "768px",
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Header />
              <GlobalStyles />
                  
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/tutoring" element={<Tutoring />} />
          <Route path="/cheatsheets" element={<Cheatsheets />} />
        </Routes>
        <Footer />
      </>
    </ThemeProvider>
  );
}
