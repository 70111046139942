import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.header};
  padding: 2rem 0;
  color: #505050;
`;

export const Nav = styled.nav`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #fff;
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  ul li {
    margin-right: 2rem;
  }

  ul li a {
    text-decoration: none;
    color: darkgrey;
    &:hover {
      color: #505050;
    }
  }

  ul li a.active {
    border-top: solid grey;
    padding: 0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }
`;

export const Logo = styled.h1`
  text-shadow: 2px 2px 4px #000000;
  color: white;
  @media (max-width: ${({ theme }) => theme.mobile}) {
  margin-bottom: 40px;
`;



export const Image = styled.img`
  width: 375px;
  margin-left: 40px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 40px 0 30px;
  }
`;
