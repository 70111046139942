const tutors = [
  {
    id: 1,
    name: "Michael",
    price: "$35/hr",
    phone: "707-704-9090",
    email: "frenchmike@gmail.com",
    education: ["BS Computer Science", "BS Math in progress"],
    courses: [
      { name: "ACT math prep", level: "high school" },
      { name: "SAT math prep", level: "high school" },
      { name: "College Algegra", level: "university" },
      { name: "Trigonometry", level: "university" },
      { name: "Pre-calculus", level: "university" },
      { name: "Calculus 1", level: "university" },
      { name: "Calculus 2", level: "university" },
      { name: "Intro to Statistics", level: "university" },
    ],
    prices: [
      { name: "online", price: "$35/hr" },
      { name: "in person", price: "$35/hr + travel fee" },
      { name: "travel fee", price: "depends on distance" },
      { name: "group", price: "$25 per person" },
    ],
    body: "I m passionate about helping people succeed.  I currently tutor university students for college algebra, trig, calculus 1 & 2.  I also tutor high school students for the ACT / SAT math portion.",
    image: "michael.jpg",
  },
  {
    id: 2,
    name: "Anudari",
    price: "$40/hr",
    phone: "N/A",
    email: "N/A",
    education: ["BS Computer Science", "BS Math in progress"],
    courses: [
      { name: "ACT math prep", level: "high school" },
      { name: "SAT math prep", level: "high school" },
      { name: "College Algegra", level: "university" },
      { name: "Trigonometry", level: "university" },
      { name: "Pre-calculus", level: "university" },
      { name: "Calculus 1", level: "university" },
      { name: "Calculus 2", level: "university" },
      { name: "Intro to Statistics", level: "university" },
    ],
    prices: [
      { name: "online", price: "$35/hr" },
      { name: "in person", price: "$35/hr + travel fee" },
      { name: "travel fee", price: "varies per area" },
      { name: "group", price: "$25 per person" },
    ],
    body: "I m passionate about helping people succeed.  I currently tutor university students for college algebra, trig, calculus 1 & 2.  I also tutor high school students for the ACT / SAT math portion.",
    image: "anudari.jpg",
  },
];

export default tutors;
