import { StyledCard, StyledUl, Styled500 } from "./styles/Card.styled";
import { Button } from "./styles/Button.styled";
import TutorModal from './TutorModal';
import { Link } from "react-router-dom";

export default function Card({
  tutor: { name, price, phone, email, education, courses, prices, body, image },
}) {
  return (
    <>
      <StyledCard>
        <div>
          <img src={`./images/${image}`} alt="" />
        </div>
        <div>
          <h3>
            <Styled500>{name}</Styled500>
          </h3>
          <StyledUl>
            {education.map((item, index) => {
              return <li key={index}> {item}</li>;
            })}
          </StyledUl>
          <p>{body}</p>
        </div>
        <div>
          <Styled500>{price}</Styled500>
          <ul>
            <li>
              <TutorModal title="Prices" prices={prices} />
            </li>
            <li>
              <TutorModal title="Courses" courses={courses} />
            </li>
          </ul>
          <ul>
            <li>✆ {phone}</li>
            <li>📧 {email}</li>
          </ul>
          <Button bg="#000" color="#fff">
            <Link to="/contact" style={{ textDecoration: "none" }}>
              Book Tutor
            </Link>
          </Button>
        </div>
      </StyledCard>
    </>
  );
}
