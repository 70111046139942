import styled from 'styled-components';

export const Button = styled.button`
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    background-color: ${({bg})=> bg || '#fff'};
    color: ${({ color }) => color || '#000'};
    opacity: 0.7;
    &:hover{
        opacity: 1;
        transform: scale(0.98);
    }
`