import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { StyledSocialIcons } from './styles/SocialIcons.styled';


export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a
          href="https://twitter.com/math_jitsu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/profile.php?id=100089216001158&is_tour_completed=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/michael-seutin-83a4aa21"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/mathjitsu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </li>
    </StyledSocialIcons>
  );
}