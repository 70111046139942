import { Container } from "../components/styles/Container.styled";
import ComingSoon from "../components/ComingSoon";

export default function Cheatsheets() {
   return (
     <Container>
       <ComingSoon>
         <div>Blog</div>
       </ComingSoon>
     </Container>
   );
}
