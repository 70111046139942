import SocialIcons from "./SocialIcons";
import { Container } from "./styles/Container.styled";
import { Flex } from "./styles/Flex.styled";
import { StyledFooter } from "./styles/Footer.styled";

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <Flex direction={"column"}>
          <SocialIcons />
          <li>&copy; 2024 MathJitsu. All rights reserved</li>
        </Flex>
      </Container>
    </StyledFooter>
  );
}
