import { Container } from "./styles/Container.styled";
import { StyledHeader, Nav, Logo, Image } from "./styles/Header.styled";
import { Button } from "./styles/Button.styled";
import { Flex } from "./styles/Flex.styled";
import { Outlet, Link, NavLink } from "react-router-dom";


export default function Header() {
  return (
    <>
      <StyledHeader>
        <Container>
          <Nav>
            <Logo>🧮 Math Jitsu</Logo>
            <ul>
              <li>
                <NavLink to="/" activeClassName="active">
                  Home
                </NavLink>
                {/* <a href="#">Home</a> */}
              </li>
              <li>
                <NavLink to="/blog" activeClassName="active">
                  Blog
                </NavLink>
                {/* <a href="#">Blog</a> */}
              </li>
              <li>
                <NavLink to="/tutoring" activeClassName="active">
                  Tutoring
                </NavLink>
                {/* <a href="#">Tutoring</a> */}
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active">
                  Contact
                </NavLink>
                {/* <a href="#">Contact</a> */}
              </li>
              <li>
                <Link to="/cheatsheets">
                  <Button bg="#326890" color="#fff">
                    Cheatsheets
                  </Button>
                </Link>
              </li>
            </ul>
          </Nav>
          <Outlet />
          <Flex>
            <div>
              <h1>Math Tutoring</h1>
              <ul>
                <li>✔︎ in person & online</li>
                <li>✔︎ SAT / ACT math prep</li>
                <li>✔︎ university level</li>
              </ul>
            </div>
            <Image src="./images/geometry_cool.png" alt="" />
          </Flex>
        </Container>
      </StyledHeader>
    </>
  );
}
