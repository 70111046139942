import React from "react";
// Components
import Card from "../components/Card";

// Styles
import { Container } from "../components/styles/Container.styled";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "../components/styles/Global";
import { theme } from "../App.js";

// Content
import tutors from "../tutors";

export default function Home() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Container>
            {tutors.map((tutor, index) => {
              return <Card key={index} tutor={tutor} />;
            })}
          </Container>
        </>
      </ThemeProvider>
    );
}
