import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TutorModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>☞ {props.title}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
              {props.title === "Courses" ? (
                <table class="table table-hover table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Course</th>
                      <th scope="col">Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.courses.map((course, index) => {
                      return (
                        <tr key={index}>
                          <td>{course.name}</td>
                          <td>{course.level}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <table class="table table-hover table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Options</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.prices.map((price, index) => {
                      return (
                        <tr key={index}>
                          <td>{price.name}</td>
                          <td>{price.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </ul>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
