import styled from 'styled-components';

export const StyledUl = styled.ul`
  color: #326890;
`;

export const Styled500 = styled.p`
    font-weight: 500;
`;

export const StyledCard = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    margin: 40px 0;
    padding: 1.5rem;


    div{
        margin-right: 1rem;
    }

    div:nth-child(1){
        flex-grow: 1;
    }

    div:nth-child(2){
        flex-grow: 2;
    }
    div:nth-child(3){
        flex-grow: 1.2;
        border-left: 1px solid darkgrey;
        padding-left: 1rem;
    }


    img{
        border-radius: 50%;
    }

    & > div {
        flex: 1;
    }
    ul {
        padding: 0;
    }

    ul li {
        list-style: none;
    }

    ul li a {
        text-decoration: none;
        color: darkred;
        font-weight: 500;
        opacity: 0.7;
    }
    ul li a:hover {
        opacity: 1;
    }


    @media(max-width: ${({theme})=>theme.mobile}) {
        flex-direction: column;
    }
`